<template>
    <dashboard name="Tailoring: Items per employee" api-endpoint="/dashboard/tailoring" @refreshed="setData">
        <v-simple-table>
            <thead>
                <tr>
                    <th>Employee</th>
                    <th class="text-right">Items tailored</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-center grey--text" v-if="data == null || data.length == 0">
                    <td colspan="2">No data available</td>
                </tr>
                <tr v-else v-for="item in data" :key="item.id">
                    <td>{{ item.employee }}</td>
                    <td class="text-right">{{ item.qty }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </dashboard>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue';

export default {
    data: () => ({
        data    : null,
    }),
    methods: {
        setData(data) {
            this.data = data;
        }
    },
    components: {
        Dashboard
    }    
}
</script>

<style lang="scss" scoped>

</style>