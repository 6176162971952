<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-select :value="dashboard" @input="changeDashboard" :items="dashboards" placeholder="Select dashboard" flat hide-details solo-inverted 
                item-text="name" item-value="id">
                <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                            <!-- <v-list-item-subtitle>{{ item.station }}</v-list-item-subtitle> -->
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-chip label small>{{ item.station }}</v-chip>
                        </v-list-item-action>
                    </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                    <div>
                        <v-chip label small>{{ item.station }}</v-chip> {{ item.name }}
                    </div>
                </template>
            </v-select>
        </div>

        <v-card flat class="mt-3" :loading="loading">
            <v-card-text class="pa-0">
                <checkin-shoes @loading="loading = true" @loaded="loading = false" v-if="dashboard == 0"></checkin-shoes>
                <checkin-items-employee @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 1"></checkin-items-employee>
                <ready-items-employee @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 2"></ready-items-employee>
                <hall-items @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 4"></hall-items>
                <admin @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 5"></admin>
                <tailoring-employee @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 6"></tailoring-employee>
                <inspections-employee @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 7"></inspections-employee>
                <order-status @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 8"></order-status>
                <products @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 9"></products>
                <orders-to-finish @loading="loading = true" @loaded="loading = false" v-else-if="dashboard == 10"></orders-to-finish>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CheckinShoes from '@/dashboards/CheckinShoes.vue';
import CheckinItemsEmployee from '@/dashboards/CheckinItemsEmployee.vue';
import ReadyItemsEmployee from '@/dashboards/ReadyItemsEmployee.vue';
import HallItems from '@/dashboards/HallItems.vue';
import TailoringEmployee from '@/dashboards/TailoringEmployee.vue';
import InspectionsEmployee from '@/dashboards/InspectionsEmployee.vue';
import Admin from '@/dashboards/Admin.vue';
import OrderStatus from '@/dashboards/OrderStatus.vue';
import Products from '@/dashboards/Products.vue';
import OrdersToFinish from '@/dashboards/OrdersToFinish.vue';

export default {
    data: () => ({
        loading     : false,

        dashboards  :[
            { id: 0, name: 'Shoes', station: 'Check-in' },
            { id: 1, name: 'Items per employee', station: 'Check-in' },
            { id: 2, name: 'Scanned items per employee', station: 'In-storage' },
            { id: 3, name: 'Items pressed per employee', station: 'In-storage' },
            { id: 4, name: 'Items needed by hall', station: 'QC' },
            { id: 5, name: 'Admin center', station: 'Processing' },
            { id: 6, name: 'Items tailored per employee', station: 'Tailoring' },
            // { id: '', name: '', station: 'Waiting inspection' }, // validar que dashboard sería este
            { id: 7, name: 'Items inspected per employee', station: 'Final inspection' },
            { id: 8, name: 'Order status', station: 'Processing' },
            { id: 9, name: 'Products', station: 'Processing' },
            { id: 10, name: 'Orders To Finish', station: 'Processing' },
        ]
    }),
    computed: {
        ...mapGetters({
            dashboard   : 'dashboard/dashboardId'
        })
    },
    methods: {
        ...mapActions({
            setDashboard    : 'dashboard/setDashboard'
        }),
        changeDashboard(value) {

            this.setDashboard(value);
        }
    },
    components: {
        CheckinShoes,
        CheckinItemsEmployee,
        ReadyItemsEmployee,
        HallItems,
        TailoringEmployee,
        InspectionsEmployee,
        Admin,
        OrderStatus,
        Products,
        OrdersToFinish
    }
}
</script>

<style lang="scss" 
        CheckinItemsEmployeescoped>

</style>