<template>
    <dashboard name="Check-in: Items per employee" api-endpoint="/dashboard/check-in" @refreshed="setData" @fullscreen="reflowChart">
        <v-row>
            <v-col cols="12" lg="5">
                <highcharts :options="chartOptions" ref="chart" />
            </v-col>
            <v-col cols="12" lg="7">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Employee</th>
                            <th class="text-right">Items scanned</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center grey--text" v-if="data == null || data.length == 0">
                            <td colspan="2">No data available</td>
                        </tr>
                        <tr v-else v-for="item in data" :key="item.id">
                            <td>{{ item.employee }}</td>
                            <td class="text-right">{{ item.qty }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </dashboard>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue';
import { Chart } from 'highcharts-vue';

export default {
    data: () => ({
        data    : null,
    }),
    computed: {
        chartOptions() {
            if( this.data == null )
                return {};

            let data = this.data.map(i => ({ name: i.employee, y: parseInt( i.qty ) }));

            return {
                chart: { type: 'pie' },
                title: { text: null },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                legend: {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle',
                  borderWidth: 0
                },
                series: [{
                    name: 'Items checked in',
                    colorByPoint: true,
                    data: data,
                }],
            };
        },
    },
    methods: {
        setData(data) {
            this.data = data;
        },
        reflowChart() {
            // el timeout es necesario para que el div se reajuste antes de
            // reajustar la gráfica
            setTimeout(() => {
                this.$refs.chart.chart.reflow();
            }, 3);
        }
    },
    components: {
        Dashboard,
        highcharts: Chart
    }    
}
</script>

<style lang="scss" scoped>

</style>