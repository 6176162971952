<template>
    <div>
        <!-- dashboard content -->
        <div :class="fullscreen ? 'fullscreen' : ''">
            <!-- reload alert -->
            <v-alert dark color="blue-grey" v-if="timer == null && firstTry">
                <v-row no-gutters align="center">
                    <v-col class="grow">
                        Auto-refresh has been stopped because of errors while updating data.
                    </v-col>
                    <v-col class="shrink">
                        <v-btn depressed @click="restart">Try again</v-btn>
                    </v-col>
                </v-row>
            </v-alert>

            <div class="title mb-5">
                <v-row no-gutters>
                    <v-col class="grow headline">
                        {{ name }}
                        <div class="caption">
                            Refresh in: {{ timeLeft }} sec
                        </div>
                    </v-col>
                    <v-col class="shrink">
                        <v-btn icon @click="toggleFullScreen">
                            <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
                            <v-icon v-else>mdi-fullscreen</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <slot>
                Dashboard not loaded
            </slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from '@/inc/api';

export default {
    props: {
        apiEndpoint : { type: String, default: '' },
        name        : { type: String, default: '' },
        autoStart   : { type: Boolean, default: true },
    },
    data: () => ({
        errors          : 0,
        timer           : null,
        timeLeftTimer   : null,
        timeLeft        : 0,    
        firstTry        : false,
        fullscreen      : false
    }),
    computed: {
        ...mapGetters({
            refreshRate : 'dashboard/refreshRate',
            maxErrors   : 'dashboard/maxErrors',
            extraInfo   : 'dashboard/extraInfo'
        })
    },
    async mounted() {
        if( !this.autoStart )
            return;

        await this.load();
        this.start();
    },
    methods: {
        start() {
            this.timer = setInterval(() => {
                this.load();
                this.timeLeft = this.refreshRate / 1000 + 1;
            }, this.refreshRate );

            this.timeLeft = this.refreshRate / 1000 + 1;
            this.timeLeftTimer = setInterval(() => {
                this.timeLeft -= 1;
            }, 1000);
        },
        async restart() {
            this.errors = 0;
            await this.load();
            this.start();
        },
        async load() {
            try {
                let api = new API();

                this.$emit('loading');
                let res = await api.post(this.apiEndpoint, this.extraInfo == null ? {} : this.extraInfo);
                this.$emit('loaded');

                if( res.data.status !== true ) {
                    this.errors++;
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.errors = 0;
                // this.data = res.data.data;
                this.$emit('refreshed', res.data.data);
            }
            catch(error) {
                this.errors++;
                this.$emit('loaded');
                this.mxShowMessage( error, 'error' );
            }
            finally {
                this.firstTry = true;
                this.maybeStop();
            }
        },
        maybeStop() {
            if( this.errors < this.maxErrors )
                return;

            this.stop();
        },
        stop() {
            this.firstTry = false;
            if( this.timer != null ) {
                clearInterval( this.timer );
                this.timer = null;
            }
            if( this.timeLeftTimer != null ) {
                clearInterval( this.timeLeftTimer );
                this.timeLeftTimer = null;
                this.timeLeft = 0;
            }
        },
        toggleFullScreen() {
            this.fullscreen = !this.fullscreen;
            this.$emit('fullscreen');
        }
    },
    beforeDestroy() {
        this.stop();
        // if( this.timeLeftTimer != null )
        //     clearInterval( this.timeLeftTimer );
    }
}
</script>

<style lang="scss" scoped>
.fullscreen {
    position: fixed;
    padding: 12px;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #f5f5f5;
    overflow-y: auto;
    z-index: 99;
}
.fullscreen .title {
    position: sticky;
    top: 0;
}
</style>