<template>
    <dashboard name="Check-in: Shoes" api-endpoint="/dashboard/check-in/shoes" @refreshed="setData" @fullscreen="reflowChart">
        <v-row v-if="data != null">
            <v-col cols="4">
                <v-subheader class="pl-0">Shoes cleaned per employee</v-subheader>

                <highcharts :options="chartOptions" ref="chart" />

                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Employee</th>
                            <th class="text-right">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center grey--text" v-if="data.stats.length == 0">
                            <td colspan="2">No data available</td>
                        </tr>
                        <tr v-else v-for="e in data.stats" :key="e.id">
                            <td>{{ e.employee }} </td>
                            <td class="text-right">{{ e.qty }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            
            <v-col cols="8">
                <v-subheader class="pl-0">Stock</v-subheader>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th style="width: 8%;"></th>
                            <th class="text-left">Style</th>
                            <th class="text-right" v-for="(title, ix) in headers" :key="ix">{{ title }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, ix) in content" :key="ix">
                            <td>
                                <v-avatar size="32">
                                    <v-img :src="item.imgSrc"></v-img>
                                </v-avatar>
                            </td>
                            <td>{{ item.style }}</td>
                            <td :class="'text-right' + (c < 2 ? ' red--text' : '')" v-for="(c, i) in item.content" :key="i">
                                {{ c }}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </dashboard>
</template>

<script>
import Dashboard from '@/components/Dashboard';
import { Chart } from 'highcharts-vue';
import { _st } from '@/softech';

export default {
    data: () => ({
        data    : null,
        headers : []
    }),
    computed: {
        content() {
            let content = [];
            this.data.shoes.forEach(item => {
                let qtys = [];
                this.headers.forEach(h => {
                    qtys.push( item.stock.find(s => s.size == h)?.qty ?? 0 );
                });

                content.push({
                    imgSrc: item.imgSrc,
                    style: item.style, 
                    content: qtys
                });
            });

            return content;
        },
        chartOptions() {
            if( this.data == null )
                return {};

            let data = this.data.stats.map(i => ({ name: i.employee, y: parseInt( i.qty ) }));

            return {
                chart: { type: 'pie' },
                title: { text: null },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                legend: {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle',
                  borderWidth: 0
                },
                series: [{
                    name: 'Shoes cleaned',
                    colorByPoint: true,
                    data: data,
                }],
                // responsive: {
                //     rules: [{
                //         chartOptions: {
                //             legend: {
                //                 layout: 'horizontal',
                //                 align: 'center',
                //                 verticalAlign: 'bottom',
                //                 floating: true,
                //                 enabled: false
                //             }
                //         }
                //     }]
                // }
            };
        },
    },
    methods: {
        setData( data ) {
            this.data = data;

            let headers = [];
            this.data.shoes.forEach(item => {
                headers.push( ..._st.extractProp(item.stock, 'size') );
            });

            this.headers = [...new Set(headers.sort( this.compare ))]; // este formato elimina los duplicados
        },
        compare( a, b ) {
            if ( a < b ) {
                return -1;
            }

            if ( a > b ) {
                return 1;
            }

            return 0;
        },
        reflowChart() {
            // el timeout es necesario para que el div se reajuste antes de
            // reajustar la gráfica
            setTimeout(() => {
                this.$refs.chart.chart.reflow();
            }, 3);
        }
    },
    components: { 
        Dashboard,
        highcharts: Chart
    }    
}
</script>

<style lang="scss" scoped>

</style>