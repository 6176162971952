<template>
    <dashboard :name="'Items needed: Pasillo ' + (hall == null ? '?' : hall)" api-endpoint="/dashboard/hall-items" :auto-start="false" 
        @refreshed="setData" ref="dashboard">
        <v-select :items="halls" @input="updateHall" placeholder="Select hall" flat dense hide-details solo-inverted 
            item-value="id" item-text="text" v-show="hall == null"></v-select>

        <div class="text-right">
            <v-btn depressed small class="text-right" color="primary" v-show="hall != null" @click="hall = null">
                Change hall
            </v-btn>
        </div>

        <v-simple-table class="mt-4 items">
            <thead>
                <tr>
                    <th>Img</th>
                    <th>Style</th>
                    <th>Size</th>
                    <th>Desc.</th>
                    <th>Order</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, ix) in data">
                    <tr :key="ix">
                        <td>
                            <v-avatar size="32">
                                <v-img :src="item.imgSrc"></v-img>
                            </v-avatar>
                        </td>
                        <td>{{ item.style }}</td>
                        <td>{{ item.size }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.foreignId }}</td>
                    </tr>
                </template>
            </tbody>
        </v-simple-table>
    </dashboard>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue';
import { mapActions } from 'vuex';

export default {
    data: () => ({
        data    : null,
        hall    : null,
        halls   : [
            { id: '1', text: 'Pasillo 1', },
            { id: '2', text: 'Pasillo 2' },
            { id: '3', text: 'Pasillo 3' },
            { id: '4', text: 'Pasillo 4' },
            { id: '5', text: 'Pasillo 5' },
            { id: '6', text: 'Pasillo 6' },
        ]
    }),
    methods: {
        ...mapActions({
            setHall : 'dashboard/setExtraInfo'
        }),
        updateHall(item) {
            let start = this.hall == null;

            this.hall = item;
            this.setHall({
                hall: item
            });

            if( start ) {
                this.$refs.dashboard.stop();
                this.$refs.dashboard.restart();
            }
        },
        setData(data) {
            this.data = data;
        }
    },
    components: {
        Dashboard
    }    
}
</script>

<style lang="scss" scoped>
.items tr td { 
    font-size: 18px !important;
    font-weight: normal;
}
</style>