<template>
    <metabase type="dashboard" width="100%" height="880px" resource="3" :params="{ }" />
    <!-- <dashboard name="Admin Center" api-endpoint="/dashboard/admin" @refreshed="setData" :auto-start="false"> -->
        <!-- Key points -->
        <!-- <v-row>
            <v-col cols="12" lg="3">
                <v-card flat color="grey lighten-4">
                    <v-card-title class="text-h2">{{ data[0] == undefined ? 0 : data[0].tomorrow }}</v-card-title>
                    <v-card-subtitle>Orders needed for tomorrow</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3">
                <v-card flat color="grey lighten-4">
                    <v-card-title class="text-h2">{{ data[0] == undefined ? 0 : data[0].next_week }}</v-card-title>
                    <v-card-subtitle>Orders needed for next week</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3">
                <v-card flat color="grey lighten-4">
                    <v-card-title class="text-h2">{{ data[0] == undefined ? 0 : data[0].today_avg_time }}&nbsp;<span class="text-subtitle-1">mins</span></v-card-title>
                    <v-card-subtitle>Today's avg. processing time</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3">
                <v-card flat color="grey lighten-4">
                    <v-card-title class="text-h2">{{ data[0] == undefined ? 0 : data[0].avg_time }}&nbsp;<span class="text-subtitle-1">mins</span></v-card-title>
                    <v-card-subtitle>Avg. processing time</v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" lg="8">
                <highcharts :options="upcomingOrdersOptions" ref="upcomingOrdersChart" />
            </v-col>
            <v-col cols="12" lg="4">
                <v-subheader>High priority items</v-subheader>
                
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>img</th>
                            <th>Style</th>
                            <th>Size</th>
                            <th>Available</th>
                            <th>Pickup date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, ix) in data[2]">
                            <tr :key="ix">
                                <td>
                                    <v-avatar size="25" tile class="rounded">
                                        <v-img :src="item.img_src"></v-img>
                                    </v-avatar>
                                </td>
                                <td>{{ item.style }}</td>
                                <td>{{ item.size }}</td>
                                <td>{{ item.available }}</td>
                                <td>{{ item.want_date }}</td>
                            </tr>
                        </template>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row> -->

        <!-- Items info -->
        <!-- <v-row>
            <v-col cols="12" lg="6">
                <v-subheader>Items qty. by status</v-subheader>

                <highcharts :options="itemQtyChartOption" ref="itemQtyChart" />

                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th class="text-right">Qty</th>
                            <th class="text-right" width="50px">%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, ix) in data[3]">
                            <tr :key="ix">
                                <td>{{ item.status }}</td>
                                <td class="text-right">{{ numberWithCommas( item.qty ) }}</td>
                                <td class="text-right">{{ formatNumber( item.qty / totalItems * 100, 0 ) }}%</td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td><b>Total</b></td>
                            <td class="text-right">{{ numberWithCommas( totalItems ) }}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>

            <v-col cols="12" lg="6">
                <v-subheader>Orders avg. time by status</v-subheader>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th class="text-right">Avg. Time (mins)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Processing</td>
                            <td class="text-right">15</td>
                        </tr>
                        <tr>
                            <td>QC Processing</td>
                            <td class="text-right">32</td>
                        </tr>
                        <tr>
                            <td>Tailoring</td>
                            <td class="text-right">46</td>
                        </tr>
                        <tr>
                            <td>Waiting for Final Inspection</td>
                            <td class="text-right">32</td>
                        </tr>
                        <tr>
                            <td>Shipping</td>
                            <td class="text-right">20</td>
                        </tr>
                        <tr>
                            <td>Truck Line</td>
                            <td class="text-right">62</td>
                        </tr>
                        <tr>
                            <td>Returned</td>
                            <td class="text-right">240</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

    </dashboard> -->
</template>

<script>
import Metabase from '@/components/Metabase.vue';
// import Dashboard from '@/components/Dashboard.vue';
// import { Chart } from 'highcharts-vue';
// import { _st } from '@/softech';

// import { API } from '@/inc/api';

export default {
    data: () => ({
        data                    : [],
    }),
    // computed: {
    //     upcomingOrdersOptions() {

    //         let totals = _st.extractProp( this.data[1], 'total' );
    //         let completed = _st.extractProp( this.data[1], 'completed' );

    //         totals = totals.map(i => i = parseInt( i ));
    //         completed = completed.map(i => i = parseInt( i ));
                        
    //         return {
    //             chart: { 
    //                 type: 'areaspline',
    //             },
    //             title: { 
    //                 text: 'Order fulfillment',
    //                 align: 'left',
    //                 margin: 30,
    //             },
    //             subtitle: { 
    //                 text: 'Shows the progress of order fulfillment for upcoming weeks', 
    //                 align: 'left' 
    //             },
    //             yAxis: {
    //                 title: {
    //                     text: 'Qty'
    //                 },
    //             },
    //             xAxis: {
    //                 labels: {
    //                     formatter: (v) => [ 'This week', 'Next week', 'Next 2 weeks', 'Next 3 weeks' ][v.value]
    //                 }
    //             },
    //             plotOptions: {
    //                 series: {
    //                     pointStart: 0
    //                 },
    //                 area: {
    //                     stacking: 'normal',
    //                     lineColor: '#666666',
    //                     lineWidth: 1,
    //                     marker: {
    //                         lineWidth: 1,
    //                         lineColor: '#666666'
    //                     }
    //                 }
    //             },
    //             series: [{
    //                 name: 'Total',
    //                 data: totals,
    //                 color: '#FFCC80',
    //             }, {
    //                 name: 'Fulfilled',
    //                 data: completed,
    //                 color: '#81D4FA'
    //             }],
    //         };
    //     },
    //     itemQtyChartOption() {
    //         let series = [];

    //         if( !_st.isNUE( this.data[3] ) )
    //             this.data[3].forEach(d => series.push({ name: d.status, y: parseInt( d.qty ) }));

    //         return {
    //             chart: { type: 'pie' },
    //             title: { text: null },
    //             accessibility: {
    //                 point: {
    //                     valueSuffix: '%'
    //                 }
    //             },
    //             plotOptions: {
    //                 pie: {
    //                     allowPointSelect: true,
    //                     cursor: 'pointer',
    //                     dataLabels: {
    //                         enabled: true,
    //                         format: '<b>{point.name}</b>: {point.percentage:.1f} %'
    //                     }
    //                 }
    //             },
    //             legend: {
    //                 layout: 'vertical',
    //                 align: 'right',
    //                 verticalAlign: 'middle',
    //                 borderWidth: 0
    //             },
    //             series: [{
    //                 colorByPoint: true,
    //                 data: series,
    //             }],
    //         }
    //     },
    //     totalItems() {
    //         return _st.isNUE( this.data[3] ) 
    //             ? 0 
    //             : this.data[3].reduce((prev, cur) => prev + parseInt( cur.qty ?? 0 ), 0);
    //     }
    // },
    // async mounted() {
    //     // try {
    //     //     let api = new API();
    //     //     let res = await api.post('/dashboard/admin', {});

    //     //     if( res.data.status !== true ) {
    //     //         this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
    //     //         return;
    //     //     }

    //     //     this.setData(res.data.data);
    //     // }
    //     // catch(error) {
    //     //     this.mxShowMessage( error, 'error' );
    //     // }
    // },
    // methods: {
    //     setData(d) {
            
    //         this.data = [
    //             d[0][0],    // key points
    //             d[1],       // order fulfillment
    //             d[2],       // high priority items
    //             d[3]        // items qty by status
    //         ];
    //     }
    // },
    components: {
        Metabase
        // Dashboard,
        // Highcharts: Chart
    }
}
</script>

<style lang="scss" scoped>

</style>