<template>
    <metabase type="dashboard" width="100%" height="880px" resource="4" :params="{ }" />
</template>

<script>
import Metabase from '@/components/Metabase.vue';

export default {
    components: {
        Metabase
    }    
}
</script>

<style lang="scss" scoped>

</style>