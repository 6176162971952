<template>
    <dashboard name="In-storage: Items per employee" api-endpoint="/dashboard/ready" @refreshed="setData">
        <v-row v-if="data != null">
            <v-col cols="12">
                <v-subheader class="pl-0">Stock</v-subheader>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th style="width: 8%;"></th>
                            <th class="text-left">Style</th>
                            <th class="text-right" v-for="(title, ix) in headers" :key="ix">{{ title }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, ix) in content" :key="ix">
                            <td>
                                <v-avatar size="32">
                                    <v-img :src="item.imgSrc"></v-img>
                                </v-avatar>
                            </td>
                            <td>{{ item.style }}</td>
                            <td :class="'text-right' + (c < 2 ? ' red--text' : '')" v-for="(c, i) in item.content" :key="i">
                                {{ c }}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>

            <v-col cols="12">
                <v-subheader class="pl-0">Items scanned per employee</v-subheader>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Employee</th>
                            <th class="text-right">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center grey--text" v-if="data.stats.length == 0">
                            <td colspan="2">No data available</td>
                        </tr>
                        <tr v-else v-for="e in data.stats" :key="e.id">
                            <td>{{ e.employee }} </td>
                            <td class="text-right">{{ e.qty }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </dashboard>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue';
import { _st } from '@/softech';

export default {
    data: () => ({
        data    : null,
        headers : [],
    }),
    computed: {
        content() {
            let content = [];
            this.data.shirts.forEach(item => {
                let qtys = [];
                this.headers.forEach(h => {
                    qtys.push( item.stock.find(s => s.size == h)?.qty ?? 0 );
                });

                content.push({
                    imgSrc: item.imgSrc,
                    style: item.style, 
                    content: qtys
                });
            });

            return content;
        }
    },
    methods: {
        setData( data ) {
            this.data = data;

            let headers = [];
            this.data.shirts.forEach(item => {
                headers.push( ..._st.extractProp(item.stock, 'size') );
            });

            this.headers = [...new Set(headers.sort( this.compare ))]; // este formato elimina los duplicados
        },
        compare( a, b ) {
            if ( a < b ) {
                return -1;
            }

            if ( a > b ) {
                return 1;
            }

            return 0;
        }
    },
    components: {
        Dashboard
    }    
}
</script>

<style lang="scss" scoped>

</style>